
import { Columns } from 'vue-columns';
import { Vue, Component, Prop, Emit, Ref, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/ui/dropdown/Dropdown.vue';
import ContextMenuSpawner from '@/components/context-menu/ContextMenuSpawner.vue';
import SubRoleSelect from '@/components/user/SubRoleSelect.vue';
import DocumentMeta from '@/modules/documents/components/document/DocumentMeta.vue';
import ContextMenuLink from '@/components/context-menu/items/ContextMenuLink.vue';
import ContextMenuHeader from '@/components/context-menu/items/ContextMenuHeader.vue';
import Button from '@/components/ui/button';
import ButtonGroup from '@/components/ui/button-group';
import levenshteinFilter from '@/utils/levenshteinFilter';
import Icon from '@/components/ui/icon/Icon.vue';
import RolesModule from '@/store/modules/roles/RolesModule';
import { Specialisations } from '@/typings/domain';

@Component({
  name: 'RolesList',
  components: { Dropdown, ContextMenuSpawner, ContextMenuLink, ContextMenuHeader, Button, SubRoleSelect, Columns, DocumentMeta, ButtonGroup, Icon },
})
export default class RolesList extends Vue {
  @Ref('addMenu') readonly addMenu!: ContextMenuSpawner;

  @Prop({ default: [] }) roles: Array<{ id: number; name: string }>;
  
  @Prop() selectedSpecs: Array<Specialisations>;

  public search = '';
  @Prop({ default: false }) disabled: boolean;
  public specialisationValue = '';
  public showAddItem = false;
  public selected: Array<Specialisations> = [];
  public newSpecActive = false;

  get allRoles() {
    return RolesModule.roles;
  }
  get filtered() {
    return levenshteinFilter(this.allRoles, this.search);
  }
  get selectedRoles() 
  {
    if (this.selectedSpecs.length >= 1) 
    {
      this.selected = this.selectedSpecs;
      return this.selected
    }
    return null;
  }
  createSpecialisation() {
    RolesModule.createRole({ name: this.specialisationValue, isAdmin: false });
    this.newSpecActive = false;
  }
  resetSearch() {
    this.newSpecActive = false;
    this.search = '';
    this.specialisationValue = '';
  }
  remove(element: any) {
    const index = this.selected.indexOf(element);
    
    this.selected.splice(index, 1);
  }

  roleSelect(specialisation: Specialisations) {
    const index = this.selected.filter((el) => el.id === specialisation.id).length
    if (!index) 
    {
      this.selected.push(specialisation);
    }
  }

  @Watch('selected')
  @Emit('change')
  onChange() {
    return this.selected;
  }

  @Emit('select')
  select(id: number) {
    this.addMenu.close();
    return id;
  }

  @Watch('selectedRoles')
  @Emit('selectedSpec')
  selectedSpec()
  {
    return this.selected
  }

  @Emit('remove')
  removeItem(id: number) {
    return id;
  }
}
