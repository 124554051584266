
import { Vue, Component, Prop, Watch, Emit } from 'vue-property-decorator';

@Component({
  name: 'Input',
})
export default class Input extends Vue {
  public innerValue = '';

  @Prop({ default: '' }) value: string;
  @Prop({ default: null }) label: string;
  @Prop({ default: 'text' }) type: string;
  @Prop({ default: 'left' }) textAlign: string;
  @Prop({ default: '' }) placeholder: string;
  @Prop({ default: false }) error: boolean;
  @Prop({ default: false }) compact: boolean;
  @Prop({ default: false }) autocomplete: boolean;

  @Watch('innerValue')
  @Emit('change')
  onValueChanged(value: string) {
    return value.trim();
  }

  @Emit('blur')
  onBlur(e: Event) {
    return e;
  }

  @Emit('focus')
  onFocus(e: Event) {
    return e;
  }

  created() {
    this.innerValue = this.value;
  }
  @Watch('value')
  valueChanged(val:any) 
  {
    this.innerValue = val
  }
}
